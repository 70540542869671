import { useMutation } from "hooks/useMutation";
import { getRouteDeliveryPointsLinks, postSingleRouteEmail } from "./calls";
import { useRouteEmailMessages } from "./hooks";
import { assertIsDefined } from "utilities/assertIsDefined";
import cuid from "cuid";
import { useRouteHook } from "pages/routes/routesList/hooks";
import { createApiQuery } from "hooks/createApiQuery";
import { routeApi } from "./api";
import { getAnyErrorKey } from "utilities";
import { routesKeys } from "./keys";

export const useEmailSendMutation = (panelId: string) => {
  const { handleMutate, data } = useRouteEmailMessages(panelId, { enabled: false });
  const shouldUpdateRouteEmailMessages = Boolean(data);
  const { handleMutate: handleRouteMutate } = useRouteHook(panelId);

  return useMutation(postSingleRouteEmail, {
    onMutate: args => {
      if (!shouldUpdateRouteEmailMessages) {
        return handleRouteMutate(draft => {
          const orderToUpdate = draft.orders.find(draftOrder => draftOrder.id === args.orderId);
          assertIsDefined(orderToUpdate);
          orderToUpdate.hasEmailSent = true;
        });
      }

      const rollback = handleMutate(draft => {
        const orderToUpdate = draft.orders.find(draftOrder => draftOrder.id === args.orderId);
        assertIsDefined(orderToUpdate);

        orderToUpdate.isDeliveryDateConfirmed = false;
        orderToUpdate.email = {
          status: "SENT",
          body: "",
          subject: "",
          failureReason: "",
          id: cuid(),
        };
      });
      return rollback;
    },
    onSuccess: args => {
      if (!shouldUpdateRouteEmailMessages) return;
      handleMutate(draft => {
        const orderToUpdate = draft.orders.find(draftOrder => draftOrder.id === args.content.order);
        assertIsDefined(orderToUpdate);

        orderToUpdate.email = {
          status: args.content.status,
          body: args.content.body,
          subject: args.content.subject,
          failureReason: args.content.failureReason,
          id: args.content.id,
        };
      });
    },
    onError: (error, _, rollback) => {
      rollback?.(error);
    },
  });
};

const useRouteSynchronize = (close?: () => void) => {
  return useMutation(routeApi.postRouteSynchronize, ({ queryClient, toastr }) => ({
    onSuccess: (_, id) => {
      queryClient.invalidateQueries(routesKeys.route(String(id)));
      close?.();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Zsynchronizowano zamówienia i punkty trasy",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useSetStatusInExternalService = () => {
  return useMutation(routeApi.postStatusInExternalService, ({ queryClient, toastr }) => ({
    onSuccess: (_, { route }) => {
      queryClient.invalidateQueries(routesKeys.route(String(route)));
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Zsynchronizowano zamówienia z zewnętrznych serwisów",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useRouteSynchronizeDetails = createApiQuery(routeApi.getRouteSynchronizeDetails);
const useRouteDeliveryPointsLinks = createApiQuery(getRouteDeliveryPointsLinks);

export const routeActions = {
  useEmailSendMutation,
  useRouteDeliveryPointsLinks,
  useRouteSynchronizeDetails,
  useRouteSynchronize,
  useSetStatusInExternalService,
};
