import React, { InputHTMLAttributes } from "react";
import { IconRendererIcon } from "../shared/IconRenderer";

type ErrorMessage = string;

export enum Theme {
  LIGHT = "light",
  DARK = "dark",
}

export enum Size {
  DEFAULT = "default",
  SMALL = "small",
}

export interface TextFieldProps
  extends Pick<
    InputHTMLAttributes<HTMLInputElement>,
    "onKeyDown" | "onBlur" | "readOnly" | "disabled" | "onFocus" | "placeholder"
  > {
  inputClassName?: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  containerClassName?: string;
  endIcon?: IconRendererIcon;
  error?: ErrorMessage | boolean;
  helperText?: string;
  label?: string;
  startIcon?: IconRendererIcon;
  /**
   * Used as custom space at the end of the input before endIcon
   * @example 
   * endInputSection={<Spinner />}
   * endInputSection={<MdiClose />}
  */
  EndInputSection?: React.ReactNode;
  theme?: `${Theme}`;
  size?: `${Size}`;
}
