import { FilterType } from "components/common/filters/types";
import { FilterList } from "components/common/moduleNavigation/components/filtersSection/filterList/FilterList";
import descriptionIcon from "assets/images/descriptionLight.svg";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { PurchaseInvoices } from "routes/PurchaseInvoices";
import { countryCodes, currenciesToPick } from "CONSTANTS";
import { tradingDocumentConstants } from "constants/tradingDocuments";

export const purchaseInvoices: ModuleLink = {
  url: "purchases",
  label: "Faktury Zakupowe",
  icon: descriptionIcon,
  subSection: (
    <FilterList
      filters={[
        {
          type: FilterType.DateRange,
          label: "data sprzedaży",
          name: ["soldAfter", "soldBefore"],
          showMonths: true,
        },
        {
          type: FilterType.DateRange,
          label: "data wystawienia",
          name: ["fromInvoiceIssueDate", "toInvoiceIssueDate"],
          showMonths: true,
        },
        {
          type: FilterType.Select,
          label: "państwo",
          name: "countryCode",
          multiple: true,
          kind: "label",
          options: countryCodes.map(({ icon, value }) => ({
            label: value,
            value,
            icon,
          })),
        },
        {
          type: FilterType.Search,
          label: "konto sprzedażowe",
          name: "salesAccount",
          searchBy: "salesAccounts",
        },
        {
          type: FilterType.Select,
          label: "status faktury",
          name: "status",
          options: [
            { label: "Zatwierdzone", value: "CONFIRMED" },
            { label: "Niezatwierdzone", value: "NEW" },
          ],
        },
        {
          type: FilterType.Select,
          label: "status połączenia z PZ",
          name: "isGRNConfirmed",
          options: [
            { label: "Połączono", value: "true" },
            { label: "Nie połączono", value: "false" },
          ],
        },
        {
          type: FilterType.Select,
          label: "status płatności",
          name: "isPaid",
          options: [
            { label: "Opłacono", value: "true" },
            { label: "Nie opłacono", value: "false" },
          ],
        },
        {
          type: FilterType.Select,
          label: "waluta",
          name: "currency",
          kind: "label",
          options: currenciesToPick,
          multiple: true,
        },
        {
          type: FilterType.Select,
          label: "sposób płatności",
          name: "paymentType",
          options: tradingDocumentConstants.paymentMethodsOptions,
        },
        {
          type: FilterType.SelectUser,
          label: "kto się zajmuje?",
          multiple: true,
          kind: "user",
          name: "isAssigned",
          searchBy: "standardUsers",
        },
        {
          type: FilterType.Search,
          label: "kontrahent",
          name: "sellers",
          multiple: true,
          searchBy: "businessEntities",
          overrides: {
            businessEntitiesKind: "EXTERNAL",
          },
        },
        {
          type: FilterType.Date,
          label: "data przelewu",
          name: "transferDate",
        },
        {
          type: FilterType.Select,
          label: "zaległe faktury",
          name: "isPaymentOverdue",
          options: [
            { label: "Tak", value: "true" },
            { label: "Nie", value: "false" },
          ],
        },
        {
          type: FilterType.Text,
          label: "nr przelewu",
          placeholder: "Szukaj nr przelewu",
          name: "transferNumber",
        },
      ]}
    />
  ),
  routing: PurchaseInvoices,
};
