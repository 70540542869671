import { PurchaseInvoiceToReview } from "api/trading-documents/models";
import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import styles from "../../RightPanel.module.css";
import customersIcon from "assets/images/copyrightWhite.svg";
import {
  dateFns,
  getAnyErrorKey,
  getIsoDateFormat,
  handleDateField,
  pluralize,
  queryString,
} from "utilities";
import { StateLabel } from "components/common/stateLabel";
import { useQuery, useSelector } from "hooks";
import { AdvancedSelect } from "components/common/advancedSelect/AdvancedSelect";
import { useMutation, useMutationOptions } from "hooks/useMutation";
import { patchTradingDocumentCustomer } from "api/trading-documents/calls";
import { Pagination, UUID } from "api/types";
import { Seller } from "api/sellers/models";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import { QueryClient } from "react-query";
import { DatePicker } from "components/utils/datePicker";
import { AsyncInput } from "components/utils";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { CURRENCY_TYPE, currenciesOptions } from "CONSTANTS";
import { Select } from "components/miloDesignSystem/molecules/select";
import { tradingDocumentsActions } from "api/trading-documents/actions";

interface Props {
  invoice: PurchaseInvoiceToReview;
}

export const GeneralInfoSection = ({ invoice }: Props) => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const sellers = useSelector(store => store.partials.businessEntities).filter(
    businessEntity => businessEntity.kind === "EXTERNAL",
  );
  const sellersToPick = sellers.map(seller => {
    return {
      id: seller.id,
      name: seller.companyName,
      filters: {
        companyName: seller.companyName,
      },
    };
  });

  const invoiceDeliveryDateMutation = tradingDocumentsActions.usePatchImportedPurchaseInvoice();
  const paymentDeadlineMutation = tradingDocumentsActions.usePatchImportedPurchaseInvoice();
  const signatureMutation = tradingDocumentsActions.usePatchImportedPurchaseInvoice();
  const descriptionMutation = tradingDocumentsActions.usePatchImportedPurchaseInvoice();
  const invoiceIssueDateMutation = tradingDocumentsActions.usePatchImportedPurchaseInvoice();
  const currencyMutation = tradingDocumentsActions.usePatchImportedPurchaseInvoice();

  const handleRollback = (
    prevList: Pagination<PurchaseInvoiceToReview>,
    queryClient: QueryClient,
  ) => {
    queryClient.setQueryData<Pagination<PurchaseInvoiceToReview>>(
      tradingDocumentsKeys.purchaseInvoicesToReview.list(
        queryString.stringify({
          ...query,
          pageSize: 1,
        }),
      ),
      currentList => {
        assertIsDefined(currentList);
        return {
          ...currentList,
          results: prevList.results,
        };
      },
    );
  };

  const addCustomerOptions = useMutationOptions(
    (data: { id: UUID; seller: Seller }) => {
      return patchTradingDocumentCustomer({
        seller: data.seller.id,
        sellerBankAccountNumber: data.seller.plnBankAccountNumber,
        sellerBankName: data.seller.plnBankName,
        sellerCity: data.seller.city,
        sellerCompanyName: data.seller.companyName,
        sellerEmail: data.seller.email,
        sellerFirstName: data.seller.firstName,
        sellerLastName: data.seller.lastName,
        sellerPhone: data.seller.phone,
        sellerPostCode: data.seller.postCode,
        sellerStreet: data.seller.address,
        sellerTaxId: data.seller.taxId,
        id: data.id,
      });
    },
    ({ queryClient, toastr }) => ({
      onMutate: payload => {
        const prevList = queryClient.getQueryData<Pagination<PurchaseInvoiceToReview>>(
          tradingDocumentsKeys.purchaseInvoicesToReview.list(
            queryString.stringify({
              ...query,
              pageSize: 1,
            }),
          ),
        );

        queryClient.setQueryData<Pagination<PurchaseInvoiceToReview>>(
          tradingDocumentsKeys.purchaseInvoicesToReview.list(
            queryString.stringify({
              ...query,
              pageSize: 1,
            }),
          ),
          currentList => {
            assertIsDefined(currentList);
            return {
              ...currentList,
              results: currentList.results.map(invoice => {
                if (invoice.id === payload.id) {
                  return {
                    ...invoice,
                    sellerCompanyName: payload.seller.companyName,
                    sellerTaxId: payload.seller.taxId,
                    sellerBankAccountNumber: payload.seller.plnBankAccountNumber,
                    sellerBankName: payload.seller.plnBankName,
                  };
                }

                return invoice;
              }),
            };
          },
        );

        return { prevList };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(
          tradingDocumentsKeys.purchaseInvoicesToReview.list(
            queryString.stringify({
              ...query,
              pageSize: 1,
            }),
          ),
        );
      },
      onError: (error, _, context) => {
        const { prevList } = context as { prevList: Pagination<PurchaseInvoiceToReview> };
        handleRollback(prevList, queryClient);
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const addCustomerMutation = useMutation(addCustomerOptions.mutationFn, addCustomerOptions);

  return (
    <RightPanelSection>
      <InfoLabel title="sygnatura">
        {invoice.status === "CONFIRMED" ? (
          <Typography fontSize="14" fontWeight="600">
            {invoice.signature}
          </Typography>
        ) : (
          <AsyncInput
            disabled={signatureMutation.isLoading}
            inProgress={signatureMutation.isLoading}
            onChange={signature =>
              signatureMutation.mutate({
                id: invoice.id,
                signature,
              })
            }
            overwrites={{
              container: { className: styles.input },
              input: { className: styles.input },
            }}
            placeholder="Wpisz sygnaturę"
            value={invoice.signature}
          />
        )}
      </InfoLabel>
      <InfoLabel title="opis">
        {invoice.status === "CONFIRMED" ? (
          <Typography fontSize="14" fontWeight="500">
            {invoice.description}
          </Typography>
        ) : (
          <AsyncInput
            disabled={descriptionMutation.isLoading}
            inProgress={descriptionMutation.isLoading}
            onChange={description =>
              descriptionMutation.mutate({
                id: invoice.id,
                description,
              })
            }
            overwrites={{
              container: { className: styles.input },
              input: { className: styles.input },
            }}
            placeholder="Brak opisu"
            value={invoice.description}
          />
        )}
      </InfoLabel>
      <InfoLabel title="kontrahent">
        <div className="d-flex align-items-center gap-2">
          <div className={styles.filterChip}>
            <img alt="Ikona nabywcy" src={customersIcon} />
            {invoice.sellerCompanyName ? (
              <div>{invoice.sellerCompanyName}</div>
            ) : invoice.sellerFirstName && invoice.sellerLastName ? (
              <div>
                {invoice.sellerFirstName} &nbsp;{invoice.sellerLastName}
              </div>
            ) : (
              "---"
            )}
          </div>
          <div className="body-14-600 d-flex align-items-center gap-1">
            <div className="text-grey-35">NIP: &nbsp;</div>
            {invoice.sellerTaxId ? <div>{invoice.sellerTaxId}</div> : <div>---</div>}
          </div>
          {!invoice.seller &&
            invoice.sellerCompanyName.length === 0 &&
            invoice.sellerFirstName.length === 0 &&
            invoice.sellerLastName.length === 0 && (
              <div>
                <AdvancedSelect
                  inputPlaceholder="Szukaj kontrahenta..."
                  items={sellersToPick}
                  itemsWidth={300}
                  onChange={value => {
                    if (value) {
                      const seller = sellers.find(_seller => _seller.id === value.id);
                      if (seller !== undefined) {
                        addCustomerMutation.mutate({ id: invoice.id, seller: seller });
                      }
                    }
                  }}
                  placeholder="Wybierz kontrahenta"
                  selectedItem={invoice.seller ?? null}
                  width={300}
                />
              </div>
            )}
        </div>
      </InfoLabel>
      <InfoLabel title="data wystawienia">
        {invoice.status === "CONFIRMED" ? (
          <Typography fontSize="14" fontWeight="500">
            {handleDateField(invoice.invoiceIssueDate)}
          </Typography>
        ) : (
          <DatePicker
            className={styles.formHeight}
            disabled={invoiceIssueDateMutation.isLoading}
            look="common"
            onChange={date => {
              if (!date) {
                invoiceIssueDateMutation.mutate({
                  id: invoice.id,
                  invoiceIssueDate: null,
                });
              } else {
                invoiceIssueDateMutation.mutate({
                  id: invoice.id,
                  invoiceIssueDate: getIsoDateFormat(date),
                });
              }
            }}
            overwrites={{
              container: { className: styles.formHeight },
            }}
            placeholder="Wybierz datę"
            value={invoice.invoiceIssueDate || ""}
          />
        )}
      </InfoLabel>
      <InfoLabel title="data zakupu">
        {invoice.status === "CONFIRMED" ? (
          <div className="body-14-500">
            {invoice.invoiceDeliveryDate
              ? dateFns.format(new Date(invoice.invoiceDeliveryDate), "dd.MM.yyyy")
              : "---"}
          </div>
        ) : (
          <DatePicker
            className={styles.formHeight}
            disabled={invoiceDeliveryDateMutation.isLoading}
            look="common"
            onChange={date => {
              if (!date) {
                invoiceDeliveryDateMutation.mutate({
                  id: invoice.id,
                  invoiceDeliveryDate: null,
                });
              } else {
                invoiceDeliveryDateMutation.mutate({
                  id: invoice.id,
                  invoiceDeliveryDate: dateFns.format(new Date(date), "yyyy-MM-dd"),
                });
              }
            }}
            overwrites={{
              container: { className: styles.formHeight },
            }}
            placeholder="Wybierz datę"
            value={invoice.invoiceDeliveryDate || ""}
          />
        )}
      </InfoLabel>
      <InfoLabel title="termin">
        <div className="d-flex gap-2">
          {invoice.status === "CONFIRMED" ? (
            <>
              {invoice.paymentDeadline ? (
                <div>{dateFns.format(new Date(invoice.paymentDeadline), "dd.MM.yyyy")}</div>
              ) : (
                <div>---</div>
              )}
            </>
          ) : (
            <DatePicker
              className={styles.formHeight}
              disabled={paymentDeadlineMutation.isLoading}
              look="common"
              onChange={date => {
                if (!date) {
                  paymentDeadlineMutation.mutate({
                    id: invoice.id,
                    paymentDeadline: null,
                  });
                } else {
                  paymentDeadlineMutation.mutate({
                    id: invoice.id,
                    paymentDeadline: dateFns.format(new Date(date), "yyyy-MM-dd"),
                  });
                }
              }}
              overwrites={{
                container: { className: styles.formHeight },
              }}
              placeholder="Wybierz termin"
              value={invoice.paymentDeadline || ""}
            />
          )}
          {invoice.basePaymentDeadlineDelta && (
            <StateLabel kind="cyan">
              <span>
                {invoice.basePaymentDeadlineDelta}{" "}
                {pluralize.pl(invoice.basePaymentDeadlineDelta, {
                  singular: "dzień",
                  plural: "dni",
                  other: "dni",
                })}
              </span>
            </StateLabel>
          )}
        </div>
      </InfoLabel>
      <InfoLabel title="waluta">
        {invoice.status === "CONFIRMED" ? (
          <Typography fontSize="14" fontWeight="500">
            {invoice.currency}
          </Typography>
        ) : (
          <Select
            disabled={currencyMutation.isLoading}
            items={currenciesOptions}
            onChange={currency => {
              if (currency) {
                currencyMutation.mutate({
                  id: invoice.id,
                  currency: currency as CURRENCY_TYPE,
                });
              }
            }}
            selected={invoice.currency ?? null}
          />
        )}
      </InfoLabel>
    </RightPanelSection>
  );
};
