import { UUID } from "api/types";
import { usePickingWorkspaceInventory } from "api/wms/hooks";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { CommonError } from "components/utils";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "pages/wms/shared/Workspaces.module.css";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { PickingGroupPackage, PickingPackageStatus } from "api/wms/models";
import { cx } from "utilities";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Table } from "components/miloDesignSystem/molecules/table";
import { usePickingWorkspaceColumns } from "./usePickingWorkspaceColumns";
import { WorkspaceInventoryHeader } from "pages/wms/shared/components/WorkspaceInventoryHeader";
import { countryToFlagDict } from "constants/countriesFlags";
import { UserWithInitials } from "api/users/models";

export interface PickingWorkspaceState {
  user: UserWithInitials;
  picking: UUID;
}

interface Props {
  close: () => void;
  state: PickingWorkspaceState;
}

export const PickingWorkspaceModal = ({ close, state }: Props) => {
  const { data: pickingInventory, error, isLoading, isFetching } = usePickingWorkspaceInventory(
    `${state.picking}$${state.user.id}`,
  );
  const columns = usePickingWorkspaceColumns();

  if (isLoading) return null;

  if (error)
    return (
      <Modal
        close={close}
        isOpen
        width={750}
        title={
          <Typography fontSize="20" fontWeight="700">
            Obszar roboczy
          </Typography>
        }
      >
        <div className="mt-3 py-5">
          <CommonError status={error._httpStatus_} />
        </div>
      </Modal>
    );

  assertIsDefined(pickingInventory);

  return (
    <Modal
      close={close}
      isOpen
      width={990}
      title={
        <Typography fontSize="20" fontWeight="700">
          Obszar roboczy
        </Typography>
      }
    >
      <div className={styles.lineItemContainer}>
        <div className="d-flex align-items-center gap-2 pb-1">
          <Avatar size="sm" theme="light" user={state.user} />
          <Typography fontSize="14" fontWeight="700">
            {state.user.firstName} {state.user.lastName}
          </Typography>
        </div>
        <WorkspaceInventoryHeader
          signature={pickingInventory.picking.signature}
          ramp={pickingInventory.picking.ramp}
          source="Picking"
          status={pickingInventory.picking.status}
          joinedAt={pickingInventory.user.joinedAt}
          totalProgress={pickingInventory.picking.totalProgress}
          participation={pickingInventory.user.participation}
        />
        <div className={styles.tableArea}>
          {pickingInventory.pickingGroups.map(pickingGroup => (
            <div>
              <div className={styles.orderHeader} key={pickingGroup.id}>
                <div className={styles.orderHeaderGeneralInfo}>
                  <Typography fontSize="16" fontWeight="700">
                    {pickingGroup.signature}
                  </Typography>
                  <Typography color="neutralBlack48" fontSize="12" fontWeight="700">
                    {pickingGroup.position}.
                  </Typography>
                  {pickingGroup.address.countryCode && (
                    <div className="d-flex align-items-center gap-1">
                      <img alt="kraj" src={countryToFlagDict[pickingGroup.address.countryCode]} />
                      <Typography fontSize="10" fontWeight="700">
                        {pickingGroup.address.countryCode}
                      </Typography>
                    </div>
                  )}
                </div>
                <div className={styles.orderHeaderCustomer}>
                  <Typography fontSize="12" fontWeight="700" noWrap>
                    {pickingGroup.seller ? pickingGroup.seller.name : "---"}
                  </Typography>
                </div>
                <div className={styles.orderHeaderAddress}>
                  <Typography fontSize="12" fontWeight="400">
                    {pickingGroup.address.postCode} {pickingGroup.address.city}
                  </Typography>
                </div>
              </div>
              <div className={styles.tableContainer}>
                {Boolean(pickingGroup.packages.length) && (
                  <Table<PickingGroupPackage>
                    rows={pickingGroup.packages}
                    columns={columns}
                    isLoading={isLoading || isFetching}
                    error={error}
                    uiSchema={comfortableListUiSchema}
                    overrides={() => {
                      return {
                        row: row => {
                          return {
                            className: cx({
                              [styles.finishedItem]: row.status === PickingPackageStatus.ON_RAMP,
                              [styles.inProgressItem]:
                                row.status === PickingPackageStatus.ON_THE_WAY ||
                                row.status === PickingPackageStatus.PICKED_UP,
                            }),
                          };
                        },
                      };
                    }}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.closeBtn}>
          <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
            Zamknij
          </Button>
        </div>
      </div>
    </Modal>
  );
};
