import { Header } from "./subcomponents/header/Header";
import styles from "./WarehouseSchema.module.css";
import { SearchEntityDetails } from "./subcomponents/searchEntityDetails/SearchEntityDetails";
import { useQuery, useSelector } from "hooks";
import { WarehouseSchemaToolbar } from "./subcomponents/warehouseSchemaToolbar/WarehouseSchemaToolbar";
import {
  FieldKind,
  FillingStatus,
  LayoutSchema,
  RampActivity,
  RampStatus,
  RouteCollection,
  RouteCollectionDetails,
} from "api/wms/layout/models";
import { Area } from "./subcomponents/area/Area";
import { Field } from "./subcomponents/field/Field";
import { wmsLayoutActions } from "api/wms/layout/actions";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { ModuleNavigation } from "components/common/moduleNavigation/components/moduleNavigation/ModuleNavigation";
import { cx } from "utilities";
import { useState } from "react";
import { FulfillmentEntityDetails } from "./subcomponents/fulfillmentEntityDetails/FulfillmentEntityDetails";
import { RightPanelHandler } from "components/utils";
import { RightPanel } from "pages/warehouseSchema/schema/subcomponents/rightPanel/RightPanel";
import { ListDrawerWrapper } from "components/utils/drawer";

interface SchemaProps {
  schema: LayoutSchema;
}

export const WarehouseSchema = () => {
  const { query } = useQuery();
  const { panelId, hallId } = query;
  const zoom = useSelector(state => state.ui.warehouseSchemaZoom);
  const isNavbarOpen = useSelector(store => store.ui.isNavbarOpened);
  const [showFill, setShowFill] = useState(false);
  const viewScale = zoom / 100;
  const { data: hall } = wmsLayoutActions.useLayoutSchema(Number(hallId), {
    enabled: Boolean(hallId),
  });
  const routeId = query["searchCollectionId"];
  const { data: layoutCollection } = wmsLayoutActions.useRouteLayoutCollection(
    { hallId: Number(hallId), routeId: Number(routeId) },
    {
      enabled: Boolean(routeId) && Boolean(hallId),
    },
  );

  const { data: routeDetails } = wmsLayoutActions.useRouteSearchCollection(Number(routeId), {
    enabled: Boolean(routeId),
  });

  const { data: rampStatus } = wmsLayoutActions.useRampStatus(Number(hallId), {
    enabled: Boolean(hallId),
  });

  const { data: fillingStatus } = wmsLayoutActions.useFillingStatus(Number(hallId), {
    enabled: Boolean(hallId),
  });

  return (
    <RightPanelHandler name="salesInvoices">
      {({ close, isActive, togglePanel }) => (
        <ListDrawerWrapper>
          <div
            className={cx(styles.wrapper, "d-flex flex-1 overflow-hidden overflow-auto", {
              [styles.wrapperForOpenedNavbar]: isNavbarOpen,
              [styles.wrapperForClosedNavbar]: !isNavbarOpen,
              [styles.isPanelOpened]: Boolean(panelId),
            })}
          >
            <div className="overflow-auto d-flex flex-column flex-1 position-relative outline-0">
              <div className={styles.menuToolbar}>
                <ModuleNavigation module="wms" />
              </div>
              <Header showFill={showFill} setShowFill={setShowFill} />
              <SearchEntityDetails />
              {showFill && <FulfillmentEntityDetails showFill={showFill} />}
              {hallId && hall && hall.schema ? (
                <>
                  <div className={styles.container} style={{ transform: `scale(${viewScale})` }}>
                    <MarginBottom schema={hall.schema} />
                    <MarginRight schema={hall.schema} />
                    <Background schema={hall.schema} />
                    {hall.schema?.areas.map(area => {
                      return (
                        <Area area={area} viewScale={viewScale} key={area.id}>
                          {area.fields.map(field => {
                            const color = isActive(field.id)
                              ? colorPalette.deepPurple400
                              : field.wmsId === null
                              ? colorPalette.danger900
                              : getFieldColor(
                                  routeDetails,
                                  layoutCollection,
                                  rampStatus,
                                  fillingStatus,
                                  field,
                                  showFill,
                                );

                            return (
                              <div onClick={() => togglePanel(field.id)}>
                                <Field
                                  key={`${field.kind}-${field.id}`}
                                  area={area}
                                  field={field}
                                  viewScale={viewScale}
                                  color={color}
                                />
                              </div>
                            );
                          })}
                        </Area>
                      );
                    })}
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <WarehouseSchemaToolbar />
          {hallId && panelId && <RightPanel close={close} selectedHall={Number(hallId)} />}
        </ListDrawerWrapper>
      )}
    </RightPanelHandler>
  );
};

const Background = ({ schema }: SchemaProps) => {
  const widths = schema.areas.map(area => area.coords.x + area.width).sort((a, b) => a - b);
  const heights = schema.areas.map(area => area.coords.y + area.height).sort((a, b) => a - b);
  const backgroundFactor = -40;
  return (
    <div
      className={styles.background}
      style={{
        top: backgroundFactor,
        left: 0,
        height: heights[heights.length - 1] + MARGIN_BOTTOM,
        width: widths[widths.length - 1] + MARGIN_RIGHT,
      }}
    />
  );
};
const MarginRight = ({ schema }: SchemaProps) => {
  const widths = schema.areas.map(area => area.coords.x + area.width).sort((a, b) => a - b);
  return (
    <div
      className={styles.margin}
      style={{
        height: `${MARGIN_BOTTOM}px`,
        width: `${MARGIN_TOP}px`,
        top: 0,
        left: widths[widths.length - 1],
      }}
    />
  );
};

const MarginBottom = ({ schema }: SchemaProps) => {
  const heights = schema.areas.map(area => area.coords.y + area.height).sort((a, b) => a - b);
  return (
    <div
      className={styles.margin}
      style={{
        top: heights[heights.length - 1],
        height: `${MARGIN_BOTTOM}px`,
        width: `${MARGIN_TOP}px`,
        left: 0,
      }}
    />
  );
};

export const MARGIN_TOP = 500;
export const MARGIN_BOTTOM = 1000;
export const MARGIN_RIGHT = 1000;

function getFieldColor(
  routeDetails: RouteCollectionDetails | null,
  layoutCollection: RouteCollection | null,
  rampStatuses: RampStatus[] | null,
  fillingStatus: FillingStatus | null,
  field: LayoutSchema["areas"][number]["fields"][number],
  showFill: boolean,
) {
  if (showFill) return getFieldColorForShowFill(fillingStatus, field, rampStatuses);
  if (field.kind === FieldKind.RAMP) {
    const rampStatus = rampStatuses?.find(rampStatus => rampStatus.id === field.wmsId);
    if (rampStatus?.activity === RampActivity.ACTIVE) {
      return colorPalette.success500;
    }
    const isInRoute = Boolean(layoutCollection?.ramps.includes(String(field.wmsId)));
    if (isInRoute) {
      return routeDetails?.color;
    }
  }
  if (field.kind === FieldKind.FIELD) {
    const isInRoute = Boolean(layoutCollection?.fields.includes(Number(field.wmsId)));
    if (isInRoute) {
      return routeDetails?.color;
    }
  }
  if (field.kind === FieldKind.RACK) {
    const isInRoute = Boolean(layoutCollection?.racks.includes(Number(field.wmsId)));
    if (isInRoute) {
      return routeDetails?.color;
    }
  }
  if (field.kind === FieldKind.COLUMN) {
    const isInRoute = Boolean(layoutCollection?.columns.includes(Number(field.wmsId)));
    if (isInRoute) {
      return routeDetails?.color;
    }
  }
  return "";
}

const getFieldColorForShowFill = (
  fillingStatus: FillingStatus | null,
  field: LayoutSchema["areas"][number]["fields"][number],
  rampStatuses: RampStatus[] | null,
) => {
  if (field.kind === FieldKind.RAMP) {
    const rampStatus = rampStatuses?.find(rampStatus => rampStatus.id === field.wmsId);
    if (rampStatus?.activity === RampActivity.ACTIVE) {
      return colorPalette.success500;
    }
    const isFilled = Boolean(fillingStatus?.ramps.includes(String(field.wmsId)));
    if (isFilled) {
      return colorPalette.orange200;
    }
  }
  if (field.kind === FieldKind.FIELD) {
    const isFilled = Boolean(fillingStatus?.fields.includes(Number(field.wmsId)));
    if (isFilled) {
      return colorPalette.orange200;
    }
  }
  if (field.kind === FieldKind.RACK) {
    const isFilled = Boolean(fillingStatus?.racks.includes(Number(field.wmsId)));
    if (isFilled) {
      return colorPalette.orange200;
    }
  }
  if (field.kind === FieldKind.COLUMN) {
    const isFilled = Boolean(fillingStatus?.columns.includes(Number(field.wmsId)));
    if (isFilled) {
      return colorPalette.orange200;
    }
  }
  return "";
};
