import { PackageInBasket } from "api/wms/baskets/models";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const usePackageInBasketColumns = () => {
  return useCreateTableColumns<PackageInBasket>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.uniquePackageId, {
        header: "paczka",
        size: 550,
      }),
    ];
  });
};
