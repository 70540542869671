import { AsyncInput } from "components/utils";
import styles from "./EditRecipientNamesModal.module.css";
import { CustomModal } from "components/utils/customModal";
import { TradingDocument } from "api/trading-documents/models";
import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { Button } from "components/miloDesignSystem/atoms/button";

interface Props {
  close: () => void;
  modalPosition: {
    top: number;
    left: number;
  };
  tradingDocument: TradingDocument;
}

export const EditRecipientNamesModal = ({ close, modalPosition, tradingDocument }: Props) => {
  const recipientCompanyNameMutation = usePatchTradingDocumentMutation();
  const recipientFirstNameMutation = usePatchTradingDocumentMutation();
  const recipientLastNameMutation = usePatchTradingDocumentMutation();

  return (
    <CustomModal
      animated={false}
      close={close}
      isOpen
      isShadow={false}
      overrides={{
        container: { className: styles.modalContainer },
      }}
      showCloseBtn={false}
      topPosition={modalPosition.top}
      leftPosition={modalPosition.left}
    >
      <div>
        <div className="p-3 d-flex flex-column gap-3">
          <AsyncInput
            disabled={recipientCompanyNameMutation.isLoading}
            label="Nazwa firmy"
            onChange={value =>
              recipientCompanyNameMutation.mutate({
                id: tradingDocument.id,
                recipientCompanyName: value,
              })
            }
            overwrites={{
              input: { className: styles.input },
            }}
            placeholder="nazwa firmy"
            value={tradingDocument.recipientCompanyName}
          />
          <AsyncInput
            disabled={recipientFirstNameMutation.isLoading}
            label="Imię"
            onChange={value =>
              recipientFirstNameMutation.mutate({
                id: tradingDocument.id,
                recipientFirstName: value,
              })
            }
            overwrites={{
              input: { className: styles.input },
            }}
            placeholder="imię"
            value={tradingDocument.recipientFirstName}
          />
          <AsyncInput
            disabled={recipientLastNameMutation.isLoading}
            label="Nazwisko"
            onChange={value =>
              recipientLastNameMutation.mutate({
                id: tradingDocument.id,
                recipientLastName: value,
              })
            }
            overwrites={{
              input: { className: styles.input },
            }}
            placeholder="nazwisko"
            value={tradingDocument.recipientLastName}
          />
        </div>
        <div className="d-flex align-items-center gap-3 p-3 borderTop">
          <Button className="text-uppercase" size="small" onClick={close} variant="gray">
            Anuluj
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};
