import { customerActions } from "api/customers/actions";
import { useParams } from "react-router";
import { cx, getAnyErrorKey, yup } from "utilities";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { CommonError } from "components/utils";
import { assertIsDefined } from "utilities/assertIsDefined";
import { SectionWrapper } from "pages/sales/customers/customerDetails/components/sectionWrapper/SectionWrapper";
import { Title } from "pages/sales/customers/customerDetails/components/Title";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import styles from "../../../../../shared/CustomerStyles.module.css";
import localStyles from "./GeneralInformationTab.module.css";
import { Select } from "components/miloDesignSystem/molecules/select";
import { countryCodes } from "CONSTANTS";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";

export const GeneralInformationTab = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data: customer, isLoading, error } = customerActions.useCustomer(Number(customerId));

  if (isLoading)
    return (
      <SectionWrapper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4"
        width="100%"
        minHeight={516}
      >
        <Spinner size={30} />
      </SectionWrapper>
    );

  if (error)
    return (
      <SectionWrapper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4"
        width="100%"
        minHeight={516}
      >
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </SectionWrapper>
    );

  assertIsDefined(customer);

  return (
    <div className="p-3 pb-3 d-flex flex-column">
      <Title className="mb-2">Nazwa firmy i Przedstawiciela</Title>
      <TextField.Async
        mutationHook={customerActions.usePatchCustomer}
        label="Pełna nazwa"
        transformQueryData={fullName => ({
          id: customer.id,
          toUpdate: { fullName },
        })}
        containerClassName={styles.input}
        value={customer.fullName}
        size="small"
        helperText="Będzie używana do dokumentów finansowych i w oficjalnej korespondencji"
      />
      <TextField.Async
        validation={yup.string().required("Skrócona nazwa jest wymagana")}
        mutationHook={customerActions.usePatchCustomer}
        label="Nazwa skrócona"
        transformQueryData={name => ({
          id: customer.id,
          toUpdate: { name },
        })}
        containerClassName={styles.input}
        value={customer.name}
        size="small"
        helperText="Będzie wyświetlana w aplikacji"
      />
      <TextField.Async
        mutationHook={customerActions.usePatchCustomer}
        label="Imię"
        transformQueryData={ownerFirstName => ({
          id: customer.id,
          toUpdate: { ownerFirstName },
        })}
        containerClassName={styles.input}
        value={customer.ownerFirstName}
        size="small"
      />
      <TextField.Async
        mutationHook={customerActions.usePatchCustomer}
        label="Nazwisko"
        transformQueryData={ownerLastName => ({
          id: customer.id,
          toUpdate: { ownerLastName },
        })}
        containerClassName={styles.input}
        value={customer.ownerLastName}
        size="small"
      />

      <Title className="mt-4 mb-2">Dane teleadresowe</Title>
      <TextField.Async
        mutationHook={customerActions.usePatchCustomer}
        label="Adres"
        transformQueryData={street => ({
          id: customer.id,
          toUpdate: { street },
        })}
        containerClassName={styles.input}
        value={customer.street}
        size="small"
        helperText="Np. Nazwa miejscowości, ulica, nr ulicy, nr budynku, nr lokalu"
      />
      <div className="d-flex align-items-center gap-2 mb-2">
        <TextField.Async
          mutationHook={customerActions.usePatchCustomer}
          label="Kod pocztowy"
          transformQueryData={postCode => ({
            id: customer.id,
            toUpdate: { postCode },
          })}
          containerClassName={localStyles.postCode}
          value={customer.postCode}
          size="small"
        />
        <TextField.Async
          mutationHook={customerActions.usePatchCustomer}
          label="Miasto"
          transformQueryData={city => ({
            id: customer.id,
            toUpdate: { city },
          })}
          containerClassName={localStyles.city}
          value={customer.city}
          size="small"
        />
      </div>
      <Select.Async
        mutationHook={customerActions.usePatchCustomer}
        transformQueryData={countryCode => ({
          id: customer.id,
          toUpdate: { countryCode: countryCode as string },
        })}
        className={cx("mb-2", localStyles.countryCode)}
        label="Kod kraju"
        items={countryCodes.map(countryCode => ({
          text: countryCode.value,
          value: countryCode.value,
          type: MenuItemType.ICON,
          icon: countryCode.icon,
        }))}
        selected={customer.countryCode}
      />
      <TextField.Async
        mutationHook={customerActions.usePatchCustomer}
        label="Telefon"
        transformQueryData={phone => ({
          id: customer.id,
          toUpdate: { phone },
        })}
        containerClassName={styles.input}
        value={customer.phone}
        size="small"
      />
      <TextField.Async
        validation={yup.string().email("Podaj poprawny adres email")}
        mutationHook={customerActions.usePatchCustomer}
        label="Email"
        transformQueryData={email => ({
          id: customer.id,
          toUpdate: { email },
        })}
        containerClassName={styles.input}
        value={customer.email}
        size="small"
      />
      <Title className="mt-4 mb-2">numery identyfikacyjne firmy</Title>
      <TextField.Async
        validation={yup.string().required("NIP jest wymagany")}
        mutationHook={customerActions.usePatchCustomer}
        label="NIP"
        transformQueryData={taxId => ({
          id: customer.id,
          toUpdate: { taxId },
        })}
        containerClassName={styles.input}
        value={customer.taxId}
        size="small"
        helperText="Musi składać się z 10 cyfr"
      />
      <TextField.Async
        validation={yup.string().required("REGON jest wymagany")}
        mutationHook={customerActions.usePatchCustomer}
        label="REGON"
        transformQueryData={businessRegisterNumber => ({
          id: customer.id,
          toUpdate: { businessRegisterNumber },
        })}
        containerClassName={styles.input}
        value={customer.businessRegisterNumber}
        size="small"
        helperText="Musi składać się z 9 lub 14 cyfr"
      />
    </div>
  );
};
