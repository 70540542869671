import manufacturingIcon from "assets/images/Processing.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import { dashboard } from "./routes/dashboard";
import { manufacturingPlans } from "./routes/manufacturingPlans";
import { upholsteryOrders } from "./routes/upholsteryOrders";
import { tailoringOrders } from "./routes/tailoringOrders";
import { deliveryOrders } from "./routes/deliveryOrders";
import { products } from "./routes/products";
import { indexes } from "./routes/indexes";
import { manufacturers } from "./routes/manufacturers";
import { manufacturingSchemas } from "./routes/manufacturingSchemas";
import { manufacturingStages } from "./routes/manufacturingStages";
import { manufacturingEmployees } from "./routes/manufacturingEmployees";
import favicon from "assets/images/favicons/fv_production.svg";

export const manufacturing: ModuleNavigation = {
  favicon,
  labelBackground: "#EC8B28",
  label: "Produkcja",
  iconBackground: "#6A2C11",
  icon: favicon,
  menuIcon: manufacturingIcon,
  name: "MANUFACTURING",
  url: "/manufacturing",
  navigationSections: [
    {
      navigationList: [
        dashboard,
        manufacturingPlans,
        upholsteryOrders,
        tailoringOrders,
        deliveryOrders,
        products,
        indexes,
        manufacturers,
        manufacturingSchemas,
        manufacturingStages,
        manufacturingEmployees,
      ],
    },
  ],
};
