import { createColumnHelper, AccessorFn, DisplayColumnDef, ColumnDef } from "@tanstack/react-table";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { dateFns } from "utilities/utilities";
import { Assign } from "utility-types";
import { EMPTY_VALUE } from "../createTableColumns";

export const dateColumn = <T,>(
  accessorFn: AccessorFn<T, string | null>,
  columnDef: Assign<
    Pick<DisplayColumnDef<T>, "size">,
    {
      typographyProps?: TypographyProps;
      header: string;
      /**
       * @default "dd.MM.yyyy"
       */
      format?: string;
    }
  >,
): ColumnDef<T> => {
  const baseColumnHelper = createColumnHelper<T>();
  return baseColumnHelper.accessor(accessorFn, {
    header: columnDef.header,
    size: columnDef.size,
    cell: info => {
      const value = info.getValue();
      const formattedDate = value ? dateFns.format(new Date(value), "dd.MM.yyyy") : "";

      if (!value) {
        return (
          <Typography
            fontSize={columnDef.typographyProps?.fontSize || "12"}
            fontWeight={columnDef.typographyProps?.fontWeight || "700"}
            {...columnDef.typographyProps}
          >
            {EMPTY_VALUE}
          </Typography>
        );
      }
      return (
        <Typography
          fontSize={columnDef.typographyProps?.fontSize || "12"}
          fontWeight={columnDef.typographyProps?.fontWeight || "700"}
          {...columnDef.typographyProps}
        >
          {formattedDate}
        </Typography>
      );
    },
  }) as ColumnDef<T>;
};
