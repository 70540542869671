import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import {
  DrawerRightPanel,
  RightPanelBody,
  RightPanelHeader,
  RightPanelSection,
} from "components/utils/drawer";
import { useQuery } from "hooks";
import { useDrawer } from "hooks/useDrawer";
import { assertIsDefined } from "utilities/assertIsDefined";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { getStandardDateFormat } from "utilities";
import { InfoLabel } from "components/common/infoLabel";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { orderActions } from "api/orders/actions";
import { Attributes } from "components/miloDesignSystem/molecules/attributes";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { PackageSection } from "./components/PackageSection";

export const RightPanel = () => {
  const { query } = useQuery();
  const { close } = useDrawer("freeSingleItemsQuantitiesDetails");
  const { panelId } = query;
  const { data, error, isLoading } = orderActions.useGetOrderFreeSingleItemsQuantitiesDetails(
    panelId,
    {
      enabled: Boolean(panelId),
    },
  );

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(data);

  return (
    <DrawerRightPanel className="position-relative" key={panelId}>
      <RightPanelHeader>
        <Typography fontSize="16" fontWeight="600">
          {data.name}
        </Typography>
        <div className="d-flex align-items-center justify-content-end flex-1">
          <Tooltip title="Zamknij panel">
            <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
          </Tooltip>
        </div>
      </RightPanelHeader>
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody>
          <RightPanelSection padding="px-3 pt-2 pb-1">
            <Typography fontSize="26" fontWeight="700">
              {data.name}
            </Typography>
            <div className="mb-2">
              <Attributes attributes={data.index.attributes} />
            </div>
            <InfoLabel title="kontrahent (producent)">
              {data.customer ? (
                <Typography fontSize="14" fontWeight="700">
                  {data.customer.name}
                </Typography>
              ) : (
                <EmptyValue fontSize="14" fontWeight="700" />
              )}
            </InfoLabel>
            <InfoLabel title="przyjęto">
              {data.warehouseReceivedAt ? (
                <Typography fontSize="14" fontWeight="400">
                  {getStandardDateFormat(data.warehouseReceivedAt)}
                </Typography>
              ) : (
                <EmptyValue fontSize="14" fontWeight="400" />
              )}
            </InfoLabel>
          </RightPanelSection>

          <Typography fontSize="14" fontWeight="600" color="neutralBlack48" className="px-3 py-2">
            Paczki
          </Typography>
          <PackageSection packages={data.packages} />
        </RightPanelBody>
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};
