import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import favicon from "assets/images/favicons/fv_devmode.svg";
import devPanelMenuIcon from "assets/images/devPanel.svg";
import { dashboard } from "./routes/dashboard";
import devModeIcon from "assets/images/devmode.svg";

export const developer: ModuleNavigation = {
  favicon,
  labelBackground: "#2B8EFA",
  label: "Panel developera",
  iconBackground: "#003F82",
  icon: devModeIcon,
  menuIcon: devPanelMenuIcon,
  name: "DEVELOPER",
  url: "/developer",
  navigationSections: [
    {
      navigationList: [dashboard],
    },
  ],
};
