import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { customerApi } from "./api";
import { createApiQuery } from "hooks/createApiQuery";
import { useRedux, useSelector } from "hooks";
import { useMutation } from "hooks/useMutation";
import { customerKeys } from "./keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import produce from "immer";

const useCustomerGusDetails = createApiQuery(customerApi.getCustomerGusDetails);
const useCustomers = createPaginatedApiQuery(customerApi.getCustomers);
const useCustomer = createApiQuery(customerApi.getCustomer);

const usePatchCustomer = () => {
  const [dispatch, { partials }] = useRedux();
  const partialsState = useSelector(state => state.partials);

  return useMutation(customerApi.patchCustomer, ({ queryUtils }) => ({
    onMutate: ({ id, toUpdate }) => {
      const prevPanel = queryUtils.handleMutate(customerKeys.customerDetails(id), toUpdate);
      return { prevPanel };
    },
    onSuccess: payload => {
      const newPartials = produce(partialsState, draft => {
        const customerToUpdate = draft.customers.find(customer => customer.id === payload.id);
        assertIsDefined(customerToUpdate);
        Object.assign(customerToUpdate, payload);
      });
      dispatch(partials.setPartials(newPartials));
    },
    onError: (error, { id }, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollback(customerKeys.customerDetails(id), onMutateReturn.prevPanel, error);
    },
  }));
};

export const customerActions = {
  useCustomerGusDetails,
  useCustomers,
  useCustomer,
  usePatchCustomer,
};
