import { wmsBasketsActions } from "api/wms/baskets/actions";
import { BasketDetails, PackageInBasket } from "api/wms/baskets/models";
import { EmptySection } from "components/common/emptySection/EmptySection";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { RightPanelSection } from "components/utils/drawer";
import { usePackageInBasketColumns } from "./usePackageInBasketColumns";
import { useFilters } from "hooks/useFilters";

interface Props {
  basket: BasketDetails;
}

export const PackageInBasketSection = ({ basket }: Props) => {
  const { searchParams } = useFilters({ basket: basket.id });
  const {
    data: rows,
    pagination,
    isLoading,
    isPreviousData,
    error,
  } = wmsBasketsActions.useGetPackageInBasket(searchParams);

  const tableProps = useTableFeatureConnector({
    rows,
    withPagination: { pagination },
  });

  const columns = usePackageInBasketColumns();

  return (
    <RightPanelSection padding="px-3 pt-2 pb-0">
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<PackageInBasket>
          columns={columns}
          isLoading={isLoading || isPreviousData}
          error={error}
          uiSchema={mainListUiSchema}
          {...tableProps}
          overrides={() => {
            return {
              hideHeader: !rows?.length,
              noResultComponent: <EmptySection label="Brak paczek w koszu" />,
              ...tableProps.overrides?.(),
            };
          }}
        />
      </div>
    </RightPanelSection>
  );
};
