import { LoadingDetails, LoadingStatus } from "api/wms/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader } from "components/utils/drawer";
import { useDownloadLoadingPdf } from "../../hooks/useDownloadLoadingPdf";
import { wmsLoadingActions } from "api/wms/loading/actions";

interface Props {
  close: () => void;
  loading: LoadingDetails;
}

export const PanelHeader = ({ close, loading }: Props) => {
  const startLoadingMutation = wmsLoadingActions.usePostStartLoading();
  const finishLoadingMutation = wmsLoadingActions.usePostFinishLoading();
  const deleteMutation = wmsLoadingActions.useRemoveLoading(close, loading);
  const handleDownloadLoadingPdf = useDownloadLoadingPdf();

  return (
    <RightPanelHeader>
      {!loading.startedAt && !loading.finishedAt && (
        <Button
          className="text-uppercase"
          disabled={startLoadingMutation.isLoading}
          onClick={() => startLoadingMutation.mutate({ loadingId: loading.id })}
          size="small"
          startIcon={MdiCheck}
          theme="light"
          variant="outline"
        >
          Rozpocznij załadunek
        </Button>
      )}
      {loading.startedAt && !loading.finishedAt && (
        <Button
          className="text-uppercase"
          disabled={finishLoadingMutation.isLoading}
          onClick={() => finishLoadingMutation.mutate({ loadingId: loading.id })}
          size="small"
          startIcon={MdiCheck}
          theme="light"
          variant="outline"
        >
          Zakończ załadunek
        </Button>
      )}
      <div className="d-flex align-items-center justify-content-end flex-1">
        <IconButton
          icon={<MdiQrCode size="16" />}
          onClick={() => handleDownloadLoadingPdf(loading)}
          variant="transparent"
        />
        <Menu
          disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
          menuItems={[
            {
              type: MenuItemType.TEXT,
              options: {
                disabled: loading.status !== LoadingStatus.NOT_STARTED,
                color: "red100",
                iconStart: MdiDelete,
              },
              onClick: () => deleteMutation.mutate(loading.id),
              text: "Usuń",
            },
          ]}
        />
        <span className="divider line-divider" />
        <Tooltip title="Zamknij panel">
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </Tooltip>
      </div>
    </RightPanelHeader>
  );
};
