import { usePickingPatchMutation } from "api/wms/hooks";
import { PickingDetails, PickingStatus } from "api/wms/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader } from "components/utils/drawer";
import { dateFns, getAnyErrorKey } from "utilities";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { useMutation } from "hooks/useMutation";
import { deletePicking } from "api/wms/calls";
import { wmsKeys } from "api/wms/keys";
import { useDownloadPickingPdf } from "../../hooks/useDownloadPickingPdf";

interface Props {
  close: () => void;
  picking: PickingDetails;
}

export const PanelHeader = ({ close, picking }: Props) => {
  const startUnloadingMutation = usePickingPatchMutation();
  const finishUnloadingMutation = usePickingPatchMutation();
  const handleDownloadPickingPdf = useDownloadPickingPdf();

  const deleteMutation = withDeleteConfirmation(
    useMutation(deletePicking, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        close();
        queryClient.invalidateQueries(wmsKeys.picking.list());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto picking "${picking.signature}"`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    "Czy na pewno chcesz usunąć ten picking?",
  )();

  return (
    <RightPanelHeader>
      {!picking.startedAt && !picking.finishedAt && (
        <Button
          className="text-uppercase"
          disabled={startUnloadingMutation.isLoading}
          onClick={() =>
            startUnloadingMutation.mutate({
              id: picking.id,
              toUpdate: {
                startedAt: dateFns.format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
                status: PickingStatus.IN_PROGRESS,
              },
            })
          }
          size="small"
          startIcon={MdiCheck}
          theme="light"
          variant="outline"
        >
          Rozpocznij picking
        </Button>
      )}
      {picking.startedAt && !picking.finishedAt && (
        <Button
          className="text-uppercase"
          disabled={finishUnloadingMutation.isLoading}
          onClick={() =>
            finishUnloadingMutation.mutate({
              id: picking.id,
              toUpdate: {
                finishedAt: dateFns.format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
                status: PickingStatus.FINISHED,
              },
            })
          }
          size="small"
          startIcon={MdiCheck}
          theme="light"
          variant="outline"
        >
          Zakończ picking
        </Button>
      )}
      <div className="d-flex align-items-center justify-content-end flex-1">
        <IconButton
          icon={<MdiQrCode size="16" />}
          onClick={() => handleDownloadPickingPdf(picking)}
          variant="transparent"
        />
        <Menu
          disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
          menuItems={[
            {
              type: MenuItemType.TEXT,
              options: {
                disabled: picking.status !== PickingStatus.NOT_STARTED,
                color: "red100",
                iconStart: MdiDelete,
              },
              onClick: () => deleteMutation.mutate(picking.id),
              text: "Usuń",
            },
          ]}
        />
        <span className="divider line-divider" />
        <Tooltip title="Zamknij panel">
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </Tooltip>
      </div>
    </RightPanelHeader>
  );
};
