import { useUnloadingOrders } from "api/wms/hooks";
import { UnloadingDetails, UnloadingLineItem, UnloadingStatus } from "api/wms/models";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { RightPanelSection } from "components/utils/drawer";
import { cx } from "utilities";
import { useLineItemsColumns } from "./useLineItemsColumns";
import styles from "pages/wms/shared/Wms.module.css";
import { useFilters } from "hooks/useFilters";
import { useStateModal, useToastr, useToggle } from "hooks";
import { UUID } from "api/types";
import { LineItemsDetailsModal } from "./lineItemsDetailsModal/LineItemsDetailsModal";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { AddIndexToUnloadingModal } from "./addIndexToUnloading/AddIndexToUnloadingModal";

interface Props {
  unloading: UnloadingDetails;
  visibility?: {
    addIndexButton?: boolean;
  };
}

export const OrdersSection = ({ unloading, visibility = { addIndexButton: true } }: Props) => {
  const { searchParams, setFilter } = useFilters({ page: 1, unloading: unloading.id });
  const { data: orders, error, isLoading, isPreviousData, pagination } = useUnloadingOrders(
    searchParams,
  );
  const columns = useLineItemsColumns();
  const lineItemDetailsModal = useStateModal<UUID>();
  const addIndexToUnloadingModal = useToggle();
  const toastr = useToastr();

  return (
    <>
      <RightPanelSection title="Zamówienia">
        <div className="pb-3">
          <Table<UnloadingLineItem>
            rows={orders}
            columns={columns}
            onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
            isLoading={isLoading || isPreviousData}
            error={error}
            uiSchema={comfortableListUiSchema}
            pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
            onRowClick={rowId => lineItemDetailsModal.open(String(rowId))}
            overrides={() => {
              return {
                row: row => {
                  return {
                    className: cx("cursor-pointer", {
                      [styles.cancelledItem]: row.isCancelled,
                      [styles.finishedItem]:
                        row.status === UnloadingStatus.FINISHED && !row.isCancelled,
                      [styles.inProgressItem]:
                        row.status === UnloadingStatus.IN_PROGRESS && !row.isCancelled,
                    }),
                  };
                },
              };
            }}
          />
          {visibility.addIndexButton && (
            <div className={styles.addIndexBtnContainer}>
              <Button
                className="text-uppercase"
                onClick={() => {
                  if (
                    [UnloadingStatus.CANCELLED, UnloadingStatus.FINISHED].includes(unloading.status)
                  ) {
                    toastr.open({
                      type: "warning",
                      title: "Wymagane działanie",
                      text: `Nie można dodać mebla do ${
                        unloading.status === UnloadingStatus.CANCELLED
                          ? "anulowanego"
                          : "zakończonego"
                      } rozładunku`,
                    });
                    return;
                  }
                  addIndexToUnloadingModal.open();
                }}
                size="small"
                startIcon={MdiAdd}
                variant="gray"
              >
                Dodaj mebel bez zamówienia
              </Button>
            </div>
          )}
        </div>
      </RightPanelSection>
      {lineItemDetailsModal.isOpen && lineItemDetailsModal.state && (
        <LineItemsDetailsModal
          close={lineItemDetailsModal.close}
          lineItemId={lineItemDetailsModal.state}
          unloading={unloading}
        />
      )}
      {addIndexToUnloadingModal.isOpen && (
        <AddIndexToUnloadingModal close={addIndexToUnloadingModal.close} unloading={unloading} />
      )}
    </>
  );
};
