import warehouseYellowIcon from "assets/images/warehouseYellow.svg";
import warehouseIcon from "assets/images/warehouse2.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import { dashboard } from "./routes/dashboard";
import { overview } from "./routes/overview";
import { unloadings } from "./routes/unloadings";
import { warehousemen } from "./routes/warehousemen";
import { warehouse as warehouseNavigation } from "./routes/warehouse";
import { reservations } from "./routes/reservations";
import { packages } from "./routes/packages";
import { inventoryChecks } from "./routes/inventoryChecks";
import { loadings } from "./routes/loadings";
import { indexes } from "./routes/indexes";
import { products } from "./routes/products";
import { etiquettes } from "./routes/etiquettes";
import { pickings } from "./routes/pickings";
import { ramps } from "./routes/ramps";
import favicon from "assets/images/favicons/fv_warehouse.svg";
import { longHeldPackages } from "./routes/longHeldPackages";
import { warehouseSchemaModule } from "./routes/warehouseSchemaModule";
import { basketsModule } from "./routes/basketsModule";

export const wms: ModuleNavigation = {
  favicon,
  labelBackground: "#e4b700",
  label: "WMS",
  iconBackground: "#6d5805",
  icon: warehouseYellowIcon,
  menuIcon: warehouseIcon,
  name: "WMS",
  url: "/wms",
  navigationSections: [
    {
      navigationList: [
        dashboard,
        unloadings,
        pickings,
        loadings,
        ramps,
        longHeldPackages,
        warehouseSchemaModule,
        warehouseNavigation,
        basketsModule,
      ],
    },

    {
      navigationList: [products, indexes, packages, etiquettes],
    },
    {
      navigationList: [inventoryChecks, reservations],
    },
    {
      subTitle: "Analityka",
      navigationList: [overview, warehousemen],
    },
  ],
};
