import { BasketDetails } from "api/wms/baskets/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { BasketStatusTag } from "../../shared/BasketStatusTag";

interface Props {
  basket: BasketDetails;
}

export const TitleSection = ({ basket }: Props) => {
  return (
    <RightPanelSection padding="px-3 pt-2 pb-0">
      <div className="d-flex align-items-center gap-2">
        <Typography fontSize="26" fontWeight="700">
          {basket.code}
        </Typography>
        <BasketStatusTag isEmpty={basket.isEmpty} />
      </div>
    </RightPanelSection>
  );
};
