import { RadioProps, RadioTheme } from "./types";
import styles from "./Radio.module.css";
import { useMemo } from "react";
import cuid from "cuid";
import { Typography } from "../typography";
import { cx } from "utilities";
import { TypographyProps } from "../typography/types";
import { ColorPalette } from "../colorsPalette";

const labelFontSizeSelector: Record<RadioProps["size"], TypographyProps["fontSize"]> = {
  sm: "12",
  md: "14",
};

const helperTextColor: Record<RadioTheme, ColorPalette> = {
  dark: "neutralWhite48",
  light: "neutralBlack48",
};

export const Radio = ({
  checked,
  disabled,
  helperText,
  label,
  onClick,
  size,
  theme = "light",
}: RadioProps) => {
  const id = useMemo(() => cuid(), []);

  const labelColor: Record<RadioTheme, ColorPalette> = {
    dark: disabled ? "neutralWhite64" : "neutralWhite100",
    light: disabled ? "neutralBlack64" : "neutralBlack88",
  };

  return (
    <div
      id={id}
      className={cx(styles.radioContainer, {
        "cursor-not-allowed": disabled,
        "align-items-center": !Boolean(helperText),
      })}
      onClick={event => {
        event.stopPropagation();
        if (!disabled) onClick(!checked);
      }}
    >
      <div
        className={cx(styles[`radioBase-${size}`], {
          [styles[`radioBase-${theme}-checked`]]: checked,
          [styles[`radioBase-${theme}-not-checked`]]: !checked,
          [styles.disabled]: disabled,
        })}
      >
        {checked && <div className={styles[`checkedRadioInnerDot-${size}`]} />}
      </div>
      <div>
        <Typography
          color={labelColor[theme]}
          fontSize={labelFontSizeSelector[size]}
          fontWeight="700"
        >
          {label}
        </Typography>
        <Typography
          className="italic"
          color={helperTextColor[theme]}
          fontSize="10"
          fontWeight="600"
        >
          {helperText}
        </Typography>
      </div>
    </div>
  );
};
