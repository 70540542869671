import { FormInput, Modal } from "components/utils";
import { Formik, FormikHelpers } from "formik";
import { getAnyErrorKey, yup } from "utilities";
import cx from "classnames";
import { Button, useToastr } from "components/common";
import { useMutation } from "hooks/useMutation";
import { postManufacturingStage } from "api/manufacturingNew/calls";
import { PostManufacturingStage } from "api/manufacturingNew/models";
import { useQueryClient } from "react-query";
import { manufacturingNewKeys } from "api/manufacturingNew/keys";
import { getRandomColor } from "utilities/getRandomColor";
import { useSelector } from "hooks";
import { AdvancedSelect } from "components/common/advancedSelect/AdvancedSelect";
import { FormikErrorMessage } from "components/utils/errorMessage/FormikErrorMessage";

interface Props {
  close: () => void;
}

const initialValues: PostManufacturingStage = {
  name: "",
  textColor: "",
  backgroundColor: "",
  stageColumnSet: "",
};

export const CreateManufacturingStageModal = ({ close }: Props) => {
  const { backgroundColor, textColor } = getRandomColor();
  const toastr = useToastr();
  const queryClient = useQueryClient();
  const stageColumnSets = useSelector(state => state.partials.stageColumnSets);

  const createManufacturingStageMutation = useMutation(
    ({
      values,
    }: {
      values: PostManufacturingStage;
      actions: FormikHelpers<PostManufacturingStage>;
    }) => {
      return postManufacturingStage(values);
    },
    {
      onSuccess: payload => {
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Utworzono nowy etap produkcji: ${payload.name}`,
        });
        queryClient.invalidateQueries(manufacturingNewKeys.manufacturingStages());
      },
      onError: (error, { actions }) => {
        actions.setErrors(error);
        actions.setSubmitting(false);
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    },
  );

  const validationSchema = yup.object().shape({
    stageColumnSet: yup.string().required("Zestaw kolumn jest wymagany"),
    name: yup.string().required("Nazwa jest wymagana"),
  });

  const handleSubmit = (
    values: PostManufacturingStage,
    actions: FormikHelpers<PostManufacturingStage>,
  ) => {
    createManufacturingStageMutation.mutate({
      values: { ...values, textColor, backgroundColor },
      actions,
    });
  };
  return (
    <Modal
      close={close}
      isOpen={true}
      overrides={{ container: { className: "p-3" } }}
      size={{ all: { width: "480px" } }}
    >
      <>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, isSubmitting, isValid, setFieldValue, values, errors }) => (
            <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
              <div className="font-bold fs-18 mb-4">Utwórz etap produkcji</div>

              <FormInput className="mb-4" label="Nazwa etapu" name="name" />
              <AdvancedSelect
                itemsWidth="440px"
                width="100%"
                inputPlaceholder="Szukaj schematu..."
                items={stageColumnSets.map(stageColumnSet => {
                  return {
                    id: stageColumnSet.id,
                    name: stageColumnSet.name,
                    filters: {
                      name: stageColumnSet.name,
                    },
                  };
                })}
                onChange={item => {
                  if (item) {
                    setFieldValue("stageColumnSet", item.id);
                  } else {
                    setFieldValue("stageColumnSet", null);
                  }
                }}
                selectedItem={values.stageColumnSet ?? null}
                itemToDisplaySelected={item => {
                  return (
                    <div className="d-flex align-items-center body-14 fw-500">
                      {item ? item.name : "Przypisz"}
                    </div>
                  );
                }}
                overrides={{
                  toggleButton: { style: { minHeight: "32px", border: "1px solid #ccc" } },
                }}
              />
              <FormikErrorMessage name="stageColumnSet" />
              <div className="d-flex align-items-center mt-4  gap-1 ">
                <Button
                  className="fs-12 uppercase"
                  kind="create-medium-transparent"
                  onClick={close}
                >
                  <div className="btnBaseMedium btnBase">Anuluj</div>
                </Button>
                <Button
                  className="mr-3 fs-12 uppercase"
                  disabled={isSubmitting}
                  kind="submit-medium"
                  type="submit"
                >
                  <div className="btnBaseMedium btnBase">Utwórz</div>
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </>
    </Modal>
  );
};
