import { UUID } from "api/types";
import { useUnloadingWorkspaceInventory } from "api/wms/hooks";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { CommonError } from "components/utils";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "./UnloadingWorkspaceModal.module.css";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Table } from "components/miloDesignSystem/molecules/table";
import { PackageStockStatus, UnloadingGroupPackage } from "api/wms/models";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useUnloadingWorkspaceColumns } from "./useUnloadingWorkspaceColumns";
import { Button } from "components/miloDesignSystem/atoms/button";
import { cx } from "utilities";
import { WorkspaceInventoryHeader } from "pages/wms/shared/components/WorkspaceInventoryHeader";
import { countryToFlagDict } from "constants/countriesFlags";
import { UserWithInitials } from "api/users/models";

export interface UnloadingWorkspaceState {
  user: UserWithInitials;
  unloading: UUID;
}

interface Props {
  close: () => void;
  state: UnloadingWorkspaceState;
}

export const UnloadingWorkspaceModal = ({ close, state }: Props) => {
  const { data: unloadingInventory, error, isLoading, isFetching } = useUnloadingWorkspaceInventory(
    `${state.unloading}$${state.user.id}`,
  );
  const columns = useUnloadingWorkspaceColumns();

  if (isLoading) return null;

  if (error)
    return (
      <Modal
        close={close}
        isOpen
        width={750}
        title={
          <Typography fontSize="20" fontWeight="700">
            Obszar roboczy
          </Typography>
        }
      >
        <div className="mt-3 py-5">
          <CommonError status={error._httpStatus_} />
        </div>
      </Modal>
    );

  assertIsDefined(unloadingInventory);

  return (
    <Modal
      close={close}
      isOpen
      width={990}
      title={
        <Typography fontSize="20" fontWeight="700">
          Obszar roboczy
        </Typography>
      }
    >
      <div className={styles.lineItemContainer}>
        <div className="d-flex align-items-center gap-2 pb-1">
          <Avatar size="sm" theme="light" user={state.user} />
          <Typography fontSize="14" fontWeight="700">
            {state.user.firstName} {state.user.lastName}
          </Typography>
        </div>
        <WorkspaceInventoryHeader
          signature={unloadingInventory.unloading.signature}
          ramp={unloadingInventory.unloading.ramp}
          source="Rozładunek"
          status={unloadingInventory.unloading.status}
          joinedAt={unloadingInventory.user.joinedAt}
          totalProgress={unloadingInventory.unloading.totalProgress}
          participation={unloadingInventory.user.participation}
        />
        <div className={styles.tableArea}>
          {unloadingInventory.unloadingGroups.map(unloadingGroup => (
            <div>
              <div className={styles.orderHeader} key={unloadingGroup.id}>
                <div className={styles.orderHeaderGeneralInfo}>
                  <Typography fontSize="16" fontWeight="700">
                    {unloadingGroup.signature}
                  </Typography>
                  {unloadingGroup.address.countryCode && (
                    <div className="d-flex align-items-center gap-1">
                      <img alt="kraj" src={countryToFlagDict[unloadingGroup.address.countryCode]} />
                      <Typography fontSize="10" fontWeight="700">
                        {unloadingGroup.address.countryCode}
                      </Typography>
                    </div>
                  )}
                </div>
                <div className={styles.orderHeaderCustomer}>
                  <Typography fontSize="12" fontWeight="700" noWrap>
                    {unloadingGroup.suppliers && Boolean(unloadingGroup.suppliers.length)
                      ? unloadingGroup.suppliers.map(supplier => supplier.name).join(", ")
                      : "---"}
                  </Typography>
                </div>
                <div className={styles.orderHeaderAddress}>
                  <Typography fontSize="12" fontWeight="400">
                    {unloadingGroup.address.postCode} {unloadingGroup.address.city}
                  </Typography>
                </div>
              </div>
              <div className={styles.tableContainer}>
                {Boolean(unloadingGroup.packages.length) && (
                  <Table<UnloadingGroupPackage>
                    rows={unloadingGroup.packages}
                    columns={columns}
                    isLoading={isLoading || isFetching}
                    error={error}
                    uiSchema={comfortableListUiSchema}
                    overrides={() => {
                      return {
                        row: row => {
                          return {
                            className: cx({
                              [styles.finishedItem]:
                                row.stockStatus === PackageStockStatus.IN_STOCK,
                            }),
                          };
                        },
                      };
                    }}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.closeBtn}>
          <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
            Zamknij
          </Button>
        </div>
      </div>
    </Modal>
  );
};
