import { Customer } from "api/customers/models";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useCustomerColumns = () => {
  return useCreateTableColumns<Customer>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.name, {
        header: "Nazwa",
        size: 154,
        typographyProps: {
          fontSize: "16",
        },
      }),
    ];
  });
};
