import { wmsBasketsActions } from "api/wms/baskets/actions";
import { BasketDetails } from "api/wms/baskets/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelHeader } from "components/utils/drawer";

interface Props {
  close: () => void;
  basket: BasketDetails;
}

export const PanelHeader = ({ close, basket }: Props) => {
  const deleteMutation = wmsBasketsActions.useDeleteBasket(close, basket);
  const handleDownloadLabel = wmsBasketsActions.useDownloadBasketLabel();

  return (
    <RightPanelHeader>
      <div className="d-flex align-items-center justify-content-between flex-1">
        <Typography fontSize="16" fontWeight="600">
          {basket.code}
        </Typography>
        <div className="d-flex align-items-center">
          <Tooltip title="Pobierz etykietę kosza">
            <IconButton
              icon={<MdiQrCode size="16" />}
              onClick={() => handleDownloadLabel(basket.id, basket.code)}
              variant="transparent"
            />
          </Tooltip>
          <Menu
            disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
            menuItems={[
              {
                type: MenuItemType.TEXT,
                options: {
                  color: "red100",
                  iconStart: MdiDelete,
                },
                onClick: () => deleteMutation.mutate(basket.id),
                text: "Usuń",
              },
            ]}
          />
          <span className="divider line-divider" />
          <Tooltip title="Zamknij panel">
            <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
          </Tooltip>
        </div>
      </div>
    </RightPanelHeader>
  );
};
