import { FormInput, Modal } from "components/utils";
import { Formik, FormikHelpers } from "formik";
import { getAnyErrorKey } from "utilities";
import cx from "classnames";
import { Button, useToastr } from "components/common";
import { useMutation } from "hooks/useMutation";
import { postManufacturingSchema } from "api/manufacturingNew/calls";
import { PostManufacturingSchema } from "api/manufacturingNew/models";
import { useQueryClient } from "react-query";
import { manufacturingNewKeys } from "api/manufacturingNew/keys";

interface Props {
  close: () => void;
}

const initialValues: PostManufacturingSchema = {
  name: "",
};

export const CreateManufacturingSchemaModal = ({ close }: Props) => {
  const toastr = useToastr();
  const queryClient = useQueryClient();
  const createManufacturingSchemaMutation = useMutation(
    ({
      values,
    }: {
      values: PostManufacturingSchema;
      actions: FormikHelpers<PostManufacturingSchema>;
    }) => {
      return postManufacturingSchema(values);
    },
    {
      onSuccess: payload => {
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Utworzono nowy schemat produkcji: ${payload.name}`,
        });
        queryClient.invalidateQueries(manufacturingNewKeys.manufacturingSchemas());
      },
      onError: (error, { actions }) => {
        actions.setErrors(error);
        actions.setSubmitting(false);
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    },
  );

  return (
    <Modal
      close={close}
      isOpen={true}
      overrides={{ container: { className: "p-3" } }}
      size={{ all: { width: "480px" } }}
    >
      <>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            createManufacturingSchemaMutation.mutate({ values, actions });
          }}
        >
          {({ handleSubmit, isSubmitting, isValid }) => (
            <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
              <div className="font-bold fs-18 mb-4">Utwórz schemat produkcji</div>

              <FormInput className="mb-4" label="Nazwa schematu" name="name" />
              <div className="d-flex align-items-center mt-4 ">
                <Button
                  className="mr-3 fs-12 uppercase"
                  disabled={isSubmitting}
                  kind="primary"
                  type="submit"
                >
                  Utwórz
                </Button>
                <Button className="fs-12 uppercase" kind="secondary-stroke" onClick={close}>
                  Anuluj
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </>
    </Modal>
  );
};
