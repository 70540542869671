import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiCart } from "components/miloDesignSystem/atoms/icons/MdiCart";
import { BasketsRouting } from "routes/wms/BasketsRouting";

export const basketsModule: ModuleLink = {
  url: "baskets",
  label: "Kosze",
  icon: MdiCart,
  subSection: <></>,
  routing: BasketsRouting,
};
