import { useQuery } from "hooks";
import { getSearch } from "./utils/getSearch";
import { financesOverviewActions } from "api/trading-documents/overview/actions";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import {
  DrawerHelpers,
  getTablePropsBasedOnDrawerHelpers,
} from "components/utils/drawerHelpers/DrawerHelpers";
import { Table } from "components/miloDesignSystem/molecules/table";
import { SoldPackage } from "api/trading-documents/overview/models";
import { Assign } from "utility-types";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import cuid from "cuid";
import { useSoldPackagesColumns } from "./useSoldPackagesColumns";
import { dateFns, getIsoDateFormat } from "utilities";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiDownloadCsv } from "components/miloDesignSystem/atoms/icons/MdiDownloadCsv";
import { useDefaultFilters } from "hooks/useDefaultFilters";

export const SoldPackagesList = () => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query });
  const {
    data: soldPackages,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = financesOverviewActions.useSoldPackages(search);
  const downloadSoldPackagesCsv = financesOverviewActions.useDownloadSoldPackages(search);
  const columns = useSoldPackagesColumns();

  useDefaultFilters({
    grouping_variable: "DAY",
    start_date: getIsoDateFormat(dateFns.subDays(new Date(), 14)),
    end_date: getIsoDateFormat(new Date()),
  });

  return (
    <>
      <PageWrapper>
        <PageHeader
          bigTitle="Zestawienie sprzedanych paczek"
          searchInput={{
            label: "Szukaj...",
            tags: [],
          }}
          actionButtons={
            <div className="d-flex align-items-center gap-1">
              <Button
                className="text-uppercase"
                onClick={downloadSoldPackagesCsv}
                size="small"
                startIcon={MdiDownloadCsv}
                variant="gray"
              >
                Pobierz zestawienie sprzedanych paczek
              </Button>
            </div>
          }
          viewLabel="FINANCES_OVERVIEW_SOLD_PACKAGES"
        />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <DrawerHelpers name="financesSoldPackages">
            {drawerHelpers => (
              <>
                <Table<Assign<SoldPackage, { id: string }>>
                  rows={soldPackages.map(soldPackage => ({
                    ...soldPackage,
                    id: cuid(),
                  }))}
                  columns={columns}
                  onPaginationChange={paginationState => {
                    updateQuery({ ...query, page: paginationState.pageIndex });
                  }}
                  isLoading={isLoading || isPreviousData}
                  error={error}
                  pagination={pagination}
                  uiSchema={mainListUiSchema}
                  {...getTablePropsBasedOnDrawerHelpers(drawerHelpers)}
                />
              </>
            )}
          </DrawerHelpers>
        </div>
      </PageWrapper>
    </>
  );
};
