import { patchManufacturingSchema } from "api/manufacturingNew/calls";
import { manufacturingNewKeys } from "api/manufacturingNew/keys";
import { ManufacturingSchema } from "api/manufacturingNew/models";
import { AvatarWithName } from "components/common/avatarWithName";
import { InfoLabel } from "components/common/infoLabel";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { AsyncInput, StatusHandler, StatusHandlerHelpers } from "components/utils";
import { RightPanelSection } from "components/utils/drawer";
import { useQueryUtils, useToastr } from "hooks";
import { useMutation } from "hooks/useMutation";
import { dateFns, getAnyErrorKey } from "utilities";

export const GeneralInfoSection = ({
  manufacturingSchema,
  panelKey,
}: {
  manufacturingSchema: ManufacturingSchema;
  panelKey: string[];
}) => {
  const toastr = useToastr();
  const { rollback, rollbackList, handleMutate, handlePaginatedListUpdate } = useQueryUtils();
  const context = useScrollableContext();
  const patchMutation = useMutation(patchManufacturingSchema, {
    onMutate: toUpdate => {
      const prevList = handlePaginatedListUpdate(
        manufacturingNewKeys.manufacturingSchemas(),
        toUpdate.id,
        toUpdate.data,
      );
      const prevPanel = handleMutate(panelKey, toUpdate.data);
      return { prevList, prevPanel };
    },
    onError: (error, _, onMutateReturn) => {
      if (!onMutateReturn) return;
      const { prevList, prevPanel } = onMutateReturn;
      rollback(panelKey, prevPanel, error);
      if (prevList) {
        rollbackList(manufacturingNewKeys.manufacturingSchemas(), prevList, prevList.id, error);
      }
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  });

  const handleSubmit = (value: Partial<ManufacturingSchema>) => {
    patchMutation.mutate({
      data: value,
      id: manufacturingSchema.id,
    });
  };

  async function handleSubmitName(
    data: Pick<ManufacturingSchema, "name">,
    helpers: StatusHandlerHelpers,
  ) {
    if (data.name === "") {
      helpers.setErrors({ message: "Nazwa nie może być pusta" });
      return;
    }
    handleSubmit(data);
  }

  return (
    <RightPanelSection>
      <div className="fs-24" ref={context.triggerElementRef}>
        {manufacturingSchema.signature}
      </div>
      <form className="my-2">
        <StatusHandler>
          {helpers => (
            <AsyncInput
              autoComplete="off"
              name="name"
              ignoreFocus={true}
              onChange={value => handleSubmitName({ name: value }, helpers)}
              overwrites={{ container: { className: "mb-3" } }}
              label="Nazwa"
              value={manufacturingSchema.name}
              disabled={patchMutation.isLoading}
              error={helpers.errors.message}
            />
          )}
        </StatusHandler>
        <AsyncInput
          autoComplete="off"
          name="description"
          ignoreFocus={true}
          onChange={value => handleSubmit({ description: value })}
          value={manufacturingSchema.description}
          overwrites={{ container: { className: "mb-3" } }}
          label="Opis"
          placeholder="Dodaj krótki opis"
          disabled={patchMutation.isLoading}
        />
        <InfoLabel title="dodany przez">
          <>
            {manufacturingSchema && <AvatarWithName user={manufacturingSchema.createdBy} />}
            {dateFns.format(new Date(manufacturingSchema.createdAt), "dd LLL yyyy")}
          </>
        </InfoLabel>
      </form>
    </RightPanelSection>
  );
};
