import { UnloadingDetails, UnloadingStatus } from "api/wms/models";
import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import styles from "../../RightPanel.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiPauseCircle } from "components/miloDesignSystem/atoms/icons/MdiPauseCircle";
import { MdiTimer } from "components/miloDesignSystem/atoms/icons/MdiTimer";
import { dateFns, getStandardTimeFormat } from "utilities";
import { useUnloadingPatchMutation } from "api/wms/hooks";
import { isAbleToStartBreak } from "pages/wms/shared/utils/isAbleToStartBreak";

interface Props {
  unloading: UnloadingDetails;
  readOnly?: boolean;
}

export const ProgressSection = ({ unloading, readOnly = false }: Props) => {
  const pauseMutation = useUnloadingPatchMutation();

  return (
    <RightPanelSection>
      <div className="d-flex align-items-center w-100 gap-2">
        <div className="w-50 borderRight py-2">
          <InfoLabel title="Postęp" titleStyle={styles.progressTitle}>
            <div className="d-flex align-items-center gap-2">
              <Typography fontSize="14" fontWeight="700">
                {Number(unloading.totalProgress)}%
              </Typography>
              {!unloading.startedAt && (
                <Typography color="danger600" fontSize="14" fontWeight="600">
                  Rozładunek nierozpoczęty
                </Typography>
              )}
            </div>
          </InfoLabel>
          <InfoLabel title="zeskanowano">
            <Typography fontSize="14" fontWeight="600">
              {Number(unloading.scanningProgress)}%
            </Typography>
          </InfoLabel>
          <InfoLabel title="zlokalizowano">
            <Typography fontSize="14" fontWeight="600">
              {Number(unloading.locationProgress)}%
            </Typography>
          </InfoLabel>
        </div>
        <div className="w-50 pl-3 py-2">
          <InfoLabel title="Pauza" titleStyle={styles.progressTitle}>
            {isAbleToStartBreak(unloading.pauseStartedAt, unloading.pauseEndedAt) ? (
              <Button
                className="text-uppercase"
                disabled={
                  unloading.status === UnloadingStatus.NOT_STARTED ||
                  pauseMutation.isLoading ||
                  readOnly
                }
                onClick={() =>
                  pauseMutation.mutate({
                    id: unloading.id,
                    toUpdate: {
                      pauseStartedAt: dateFns.format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
                      pauseEndedAt: null,
                    },
                  })
                }
                size="small"
                startIcon={MdiPauseCircle}
                variant="gray"
              >
                Rozpocznij
              </Button>
            ) : (
              <Button
                className="text-uppercase"
                disabled={pauseMutation.isLoading || readOnly}
                onClick={() =>
                  pauseMutation.mutate({
                    id: unloading.id,
                    toUpdate: {
                      pauseEndedAt: dateFns.format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
                    },
                  })
                }
                size="small"
                startIcon={MdiTimer}
                variant="gray"
              >
                Wznów pracę
              </Button>
            )}
          </InfoLabel>
          <InfoLabel title="start">
            <Typography fontSize="14" fontWeight="600">
              {unloading.pauseStartedAt ? getStandardTimeFormat(unloading.pauseStartedAt) : "---"}
            </Typography>
          </InfoLabel>
          <InfoLabel title="koniec">
            <Typography fontSize="14" fontWeight="600">
              {unloading.pauseEndedAt
                ? getStandardTimeFormat(unloading.pauseEndedAt)
                : unloading.pauseStartedAt
                ? "trwa"
                : "---"}
            </Typography>
          </InfoLabel>
        </div>
      </div>
    </RightPanelSection>
  );
};
