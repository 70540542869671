import { ManufacturingStage } from "api/manufacturingNew/models";
import { InfoLabel } from "components/common/infoLabel";
import { StateLabel } from "components/common/stateLabel";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";

interface Props {
  manufacturingStage: ManufacturingStage;
}

export const GeneralInfoSection = ({ manufacturingStage }: Props) => {
  const context = useScrollableContext();

  return (
    <RightPanelSection>
      <div className="d-flex align-items-center gap-2 mb-2" ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {manufacturingStage.name}
        </Typography>
        <StateLabel
          kind="none"
          style={{
            backgroundColor: manufacturingStage.backgroundColor,
            color: manufacturingStage.textColor,
          }}
        >
          {manufacturingStage.name}
        </StateLabel>
      </div>
      <InfoLabel title="dodane przez">
        <div className="body-14-500">
          {manufacturingStage.implementedBy
            ? manufacturingStage.implementedBy === "IN_HOUSE"
              ? "wewnętrznie"
              : "kontraktora"
            : "---"}
        </div>
      </InfoLabel>
    </RightPanelSection>
  );
};
