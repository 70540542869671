import { useQuery, useToggle } from "hooks";
import { getSearch } from "./utils/getSearch";
import { financesOverviewActions } from "api/trading-documents/overview/actions";
import { dateFns, getIsoDateFormat, queryString } from "utilities";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import { Button } from "components/miloDesignSystem/atoms/button";
import {
  DrawerHelpers,
  getTablePropsBasedOnDrawerHelpers,
} from "components/utils/drawerHelpers/DrawerHelpers";
import { Table } from "components/miloDesignSystem/molecules/table";
import { Assign } from "utility-types";
import { SoldIndex } from "api/trading-documents/overview/models";
import cuid from "cuid";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useSoldIndexesColumns } from "./useSoldIndexesColumns";
import { BuyingPriceModal } from "./buyingPriceModal/BuyingPriceModal";
import { MdiAnalytics } from "components/miloDesignSystem/atoms/icons/MdiAnalytics";
import { MdiDownloadCsv } from "components/miloDesignSystem/atoms/icons/MdiDownloadCsv";
import { useDefaultFilters } from "hooks/useDefaultFilters";

export const SoldIndexesList = () => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query });
  const {
    data: soldIndexes,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = financesOverviewActions.useSoldIndexes(search);
  const downloadSoldIndexesCsv = financesOverviewActions.useDownloadSoldIndexesCsv(
    queryString.stringify(query),
  );
  const columns = useSoldIndexesColumns();
  const buyingPriceModal = useToggle();

  useDefaultFilters({
    time_grouping_variable: "DAY",
    start_date: getIsoDateFormat(dateFns.subDays(new Date(), 14)),
    end_date: getIsoDateFormat(new Date()),
  });

  return (
    <>
      <PageWrapper>
        <PageHeader
          bigTitle="Zestawienie sprzedanych indeksów"
          searchInput={{
            label: "Szukaj...",
            tags: [],
          }}
          actionButtons={
            <div className="d-flex align-items-center gap-1">
              <Button
                className="text-uppercase"
                onClick={buyingPriceModal.open}
                size="small"
                startIcon={MdiAnalytics}
                variant="gray"
              >
                Pobierz plik z cenami zakupowymi
              </Button>
              <span className="line-divider lineDividerMargin" />
              <Button
                className="text-uppercase"
                onClick={downloadSoldIndexesCsv}
                size="small"
                startIcon={MdiDownloadCsv}
                variant="gray"
              >
                Pobierz zestawienie sprzedanych indeksów
              </Button>
            </div>
          }
          viewLabel="FINANCES_OVERVIEW_SOLD_INDEXES"
        />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <DrawerHelpers name="financesSoldIndexes">
            {drawerHelpers => (
              <>
                <Table<Assign<SoldIndex, { id: string }>>
                  rows={soldIndexes.map(soldIndex => ({
                    ...soldIndex,
                    id: cuid(),
                  }))}
                  columns={columns}
                  onPaginationChange={paginationState => {
                    updateQuery({ ...query, page: paginationState.pageIndex });
                  }}
                  isLoading={isLoading || isPreviousData}
                  error={error}
                  pagination={pagination}
                  uiSchema={mainListUiSchema}
                  {...getTablePropsBasedOnDrawerHelpers(drawerHelpers)}
                />
              </>
            )}
          </DrawerHelpers>
        </div>
      </PageWrapper>
      {buyingPriceModal.isOpen && <BuyingPriceModal close={buyingPriceModal.close} />}
    </>
  );
};
